<template>
  <div class="login-wrapper">
    <div>
      <div class="path">
        <div class="login-logo">
          <img src="../../public/img/logo-login.png" alt="Pos/Epos">
        </div>
      </div>
        <div class="login-area">
          <div class="form-content">
            <div class="fadeIn first pt-3 pb-3 enter-text">
              Тизимга кириш
            </div>
            <form @submit.prevent="auth">
              <input
                  :disabled="otpSection"
                  v-model="login"
                  id="login"
                  placeholder="Логин"
                  type="text"
                  class="fadeIn second">
              <input
                  :disabled="otpSection"
                  v-model="password"
                  id="password"
                  placeholder="Парол"
                  type="password"
                  class="fadeIn third"
                  @keyup.enter="auth">
              <input
                  v-if="otpSection"
                  v-model="otp"
                  id="otp"
                  placeholder="Тасдиқлаш коди"
                  type="text"
                  class="fadeIn third"
                  @keyup.enter="auth">
              <input
                  :disabled="disabled"
                  placeholder="Логин"
                  type="submit"
                  @click="auth"
                  class="fadeIn fourth"
                  value="Кириш">
              <small
                  class="fadeIn text-light clearfix"
                  v-if="otpSection"
              >Сизнинг телефон рақамингизга код юборилди</small>
              <a @click="auth" class="resend-sms"
                 id="resend-sms"
                 v-if="otpSection"
              ><i class="icon-reload"></i>Қайта код жўнатиш</a>
            </form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import TokenService from "../services/TokenService";
export default {
  data() {
    return {
      login: null,
      password: null,
      otp: null,
      fingerLogin: null,
      otpSection: false,
      disabled: false
    }
  },
  methods: {
    async auth() {
      this.disabled = true;
      let deviceKey = localStorage.getItem('_deviceKey');

      if (!deviceKey) {
        deviceKey = '_' + (Math.random().toString(36) + window.navigator.userAgent).toString(36).substr(2, 9);
        localStorage.setItem('_deviceKey', deviceKey);
      }

      let params = {
        login: this.login,
        password: this.password,
        otp: this.otp,
      }
      try {
        const res = await this.$http.post('Auth/Login', params, {headers: {device_id: deviceKey}});
        if (res) {
          let response = res.result
          if (response.isAuthorized === true) {
            TokenService.setToken(response.authorization);
            localStorage.setItem('userName', response.userName);
            this.$store.state.roleId = response.role
            localStorage.setItem('role', response.role);
            await this.$router.push('/history');
          } else {
            this.otpSection = true
          }
        }
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.disabled = false
      }
    },
  }
}
</script>

<style scoped>
html {
  background-color: #00456A;
}

body {
  min-height: 100vh;
  background-color: #00456A;

}
</style>